<template>
  <div class="zxfwlist">
    <div class="header">
      <div class="h1">在线培训</div>
      <div class="h2">全面・便捷・专业 助力企业成长</div>
    </div>
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
        <el-breadcrumb-item  :to="{ path: '/zxpx' }">在线培训</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/zxpxlist' }">视频课程</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="search">
        <avue-search :option="option"   @change="loadlist(true)"
                     v-model="form">
        </avue-search>
        <div style="text-align: right;padding: 0 20px 20px 0">
          <el-button type="primary" size="mini" @click="loadlist(true)">筛选</el-button>
          <el-button  size="mini" @click="reset">重置</el-button>
        </div>
      </div>
      <div style="overflow: hidden">
        <div class="lists" @click="goxq(list)" v-for="(list,index) in arr">
            <div class="img">
              <el-image :src="list.cover"></el-image>
              <div class="time"><span style="margin-right: 10px">{{list.createTime}}</span></div>
            </div>
            <div class="flex">
              <div class="title text-overflow flex-1">{{list.title}}</div>
              <div class="cishu">{{list.readCount}}次</div>
            </div>
        </div>
      </div>
      <div class="fenye">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="page.current"
            :page-sizes="[20, 60, 100, 200]"
            :page-size="page.size"
            :total="page.total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>


import {onlineindex, onlinepage} from "../../request/moudle/zxpx";

export default {
  name: "gonggaoxq",
  components:{
  },
  data(){
    return  {
      form: {
        paixu:'descs,create_time',
        categoryId:this.$route.query.typeId
      },
      page:{
        current:1,
        size:20,
        total:0
      },
      option: {
        column: [ {
          label: '课程类型',
          prop: 'categoryId',
          props: {
            label: 'title',
            value: 'id',
          },
          multiple: false,
          dicData:[],
        },
          {
            label: '排序',
            dicData:[{
              label: '发布日期倒序',
              value: 'descs,create_time'
            },{
              label: '发布日期正序',
              value: 'ascs,create_time'
            }, {
              label: '播放量正序',
              value: 'ascs,read_count'
            }, {
              label: '播放量倒序',
              value:'descs,read_count'
            }],
            prop: 'paixu',
            multiple: false,
          },
        ]
      },
      keyword:'',
      arr:[]
    }
  },
  created() {
    this.loadconfig()
    this.loadlist()
  },
  methods:{
    loadconfig(){
      this.$api.zxpx.onlineindex().then((res)=>{
        this.datas=res.data
        const levelId=this.findObject(this.option.column, 'categoryId')
        levelId.dicData=res.data.categoryList
        levelId.dicData.unshift({ title: '不限', id: '' })
      })
    },
    loadlist(bol){
      if(bol){
        this.page.current=1
      }
      if(this.form.paixu){
        delete this.form.ascs
        delete this.form.descs
        const paixu=this.form.paixu.split(',')
        this.form[paixu[0]]=paixu[1]
      }
      let obj={
        ...this.form
      }
      delete obj.paixu
      obj.current=this.page.current
      obj.size=this.page.size
      this.$api.zxpx.onlinepage(obj).then((res)=>{
        this.arr=res.data.records
        this.page.total=res.data.total
      })
    },
    reset(){
      for (const val of this.option.column){
        if(val.multiple){
          this.form[val.prop]=['']
        }else {
          this.form[val.prop]=''
        }
      }
    },
    handleSizeChange(val){
      this.page.size=val
      this.loadlist()
    },
    handleCurrentChange(val) {
      this.page.current=val
      this.loadlist()
    },
    goxq(item){
      this.$router.push({
        path:'/zxpxxq',
        query:{
          id:item.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.col1{
  color: #C4C4C4!important;
}
.fenye{
  text-align: center;
  padding: 30px 0;
}
  .zxfwlist{
     background: #F5F5F5;
    .header{
      height: 180px;
      background: url("../../assets/banner/zxpx.png") no-repeat;
      background-size: 100% 100%;
      padding-top: 90px;
      text-align: center;
      color: #fff;
      .h1{
        font-size: 42px;
        font-weight: 500;
      }
      .h2{
        font-size: 22px;
        line-height: 80px;
      }
    }
    .content{
      width: 1200px;
      margin: 20px auto;
      .search{
        margin-top: 40px;
        margin-bottom: 40px;
        padding: 25px;
        background: #fff;
      }
      .lists{
        float: left;
        margin: 5px;
        width: 290px;
        margin-bottom: 15px;
        .img{
          width: 290px;
          height: 165px;
          position: relative;
          .time{
            position: absolute;
            bottom: 0;
            height: 25px;
            line-height: 25px;
            width: 100%;
            background: rgba(255,255,255,0.2);
            color: #fff;
            font-size: 12px;
            text-align: right;
          }
        }
        .title{
          padding: 10px 15px;
        }
        .t4{
          font-size: 12px;
          padding: 15px;
          position: relative;
        }
        .cishu{
          color: #FF9244;
          font-size: 12px;
          width: 80px;
          text-align: right;
          padding-top: 12px;
        }
      }
    }
  }
</style>